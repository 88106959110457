var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.albumId
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "release-detail",
                          params: { id: _vm.albumId }
                        }
                      }
                    },
                    [_vm._v(" Back to album... ")]
                  )
                : _vm._e(),
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Track" } },
                [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c("tr", [
                      _c("th", [_vm._v("Reporting Code")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.track.ProductDetails.ReportingCode))
                      ]),
                      _c("th", [_vm._v("Medley")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("yesno")(_vm.track.IsMedley)))
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("ISRC")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.ProductDetails.ISRC))]),
                      _c("th", [_vm._v("Single Sale Allowed")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("yesno")(_vm.track.SingleSaleAllowed))
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Attribution")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.track.ProductDetails.Attribution))
                      ]),
                      _c("th", [_vm._v("Recompilation Rights")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.track.ProductDetails.RecompilationRightsID)
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Title")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.track.ProductDetails.Title))
                      ]),
                      _c("th", [_vm._v("Duration")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.Duration))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Version Title")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.track.ProductDetails.VersionTitle))
                      ]),
                      _c("th", [_vm._v("BPM")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.BPM))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("C-Line")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.CLine))]),
                      _c("th", [_vm._v("Title Language")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("localeDescription")(
                              _vm.track.ProductDetails.TitleLanguageID
                            )
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("P-Line")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.PLine))]),
                      _c("th", [_vm._v("Lyrics Language")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("localeDescription")(
                              _vm.track.LyricsLanguageID
                            )
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Label")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.Label))]),
                      _c("th", [_vm._v("Provider")]),
                      _c("td", [_vm._v(_vm._s(_vm.track.Provider.Description))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Termset Reference")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.track.ProductDetails.TermSet.Reference)
                        )
                      ]),
                      _c("th", [_vm._v("Sub Provider")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.track.SubProvider.Description))
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Preferred Source")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("yesno")(_vm.track.ProductDetails.PrefSource)
                          )
                        )
                      ]),
                      _c("th", [_vm._v("Genre Processed")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("yesno")(
                              _vm.track.ProductDetails.GenreProcessed
                            )
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Preferred Source Processed")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("yesno")(
                              _vm.track.ProductDetails.PreferredSourceProcessed
                            )
                          )
                        )
                      ]),
                      _c("th"),
                      _c("td")
                    ]),
                    _vm.track.WorkTitle || _vm.track.Key
                      ? _c("tr", [
                          _vm.track.WorkTitle
                            ? _c("th", [_vm._v("Work title")])
                            : _vm._e(),
                          _c("td", [_vm._v(_vm._s(_vm.track.WorkTitle))]),
                          _vm.track.Key ? _c("th", [_vm._v("Key")]) : _vm._e(),
                          _c("td", [_vm._v(_vm._s(_vm.track.Key))])
                        ])
                      : _vm._e(),
                    _vm.track.MovementTitle || _vm.track.ClassicalCatalogNr
                      ? _c("tr", [
                          _vm.track.MovementTitle
                            ? _c("th", [_vm._v("Movement Title")])
                            : _vm._e(),
                          _c("td", [_vm._v(_vm._s(_vm.track.MovementTitle))]),
                          _vm.track.ClassicalCatalogNr
                            ? _c("th", [_vm._v("Classical Catalog Nr")])
                            : _vm._e(),
                          _c("td", [
                            _vm._v(_vm._s(_vm.track.ClassicalCatalogNr))
                          ])
                        ])
                      : _vm._e(),
                    _vm.track.MovementTitle
                      ? _c("tr", [
                          _c("th"),
                          _c("td"),
                          _c("th", [_vm._v("Movement nr")]),
                          _c("td", [_vm._v(_vm._s(_vm.track.MovementNr))])
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text-center", attrs: { colspan: "2" } },
                        [
                          _vm.$root.$data.me.access.ACE
                            ? _c(
                                "a",
                                {
                                  staticClass: "d-block mb-1",
                                  attrs: { href: _vm.ceUrl }
                                },
                                [_vm._v(" View in Content-Editor ")]
                              )
                            : _vm._e(),
                          _vm.track.AssetFile.IsPresent == 1
                            ? [
                                _vm.audioFile == ""
                                  ? _c(
                                      "b-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.play()
                                          }
                                        }
                                      },
                                      [_vm._v(" Play sample ")]
                                    )
                                  : _vm._e(),
                                _vm.audioPlayWarning
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.audioPlayWarning))
                                    ])
                                  : _vm._e(),
                                _vm.audioFile
                                  ? _c("audio-player", {
                                      attrs: { src: _vm.audioFile }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "td",
                        { staticClass: "text-center", attrs: { colspan: "2" } },
                        [
                          _vm.track.Security.ChangeAudio
                            ? [
                                _c("p", [
                                  _c("strong", [_vm._v("Replace audio:")])
                                ]),
                                _c("blob-upload", {
                                  staticClass: "mb-4",
                                  on: {
                                    input: function($event) {
                                      _vm.audioReplacement = $event
                                    }
                                  }
                                }),
                                _vm.audioReplacement &&
                                _vm.audioReplacementStatus == null
                                  ? [
                                      _vm.audioReplacement.status == "uploading"
                                        ? _c("b-progress", {
                                            attrs: {
                                              value:
                                                _vm.audioReplacement
                                                  .uploadPercentage,
                                              max: 100,
                                              "show-progress": ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.audioReplacement.status ==
                                      "uploadError"
                                        ? [
                                            _vm._v(
                                              " An error occurred during upload, please try again. "
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.audioReplacement.status ==
                                      "uploadComplete"
                                        ? [
                                            _vm.audioReplacementValid == true
                                              ? [
                                                  _c("audio-player", {
                                                    attrs: {
                                                      src:
                                                        _vm.audioReplacementSrc
                                                    }
                                                  }),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-modal",
                                                          rawName:
                                                            "v-b-modal.confirm-audio-modal",
                                                          modifiers: {
                                                            "confirm-audio-modal": true
                                                          }
                                                        }
                                                      ],
                                                      staticClass: "mt-3",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Confirm audio")]
                                                  )
                                                ]
                                              : _vm._e(),
                                            _vm.audioReplacementValid != true
                                              ? [
                                                  _vm._v(
                                                    " Problems found with uploaded file: "
                                                  ),
                                                  _vm._l(
                                                    _vm.audioReplacementValid,
                                                    function(msg, index) {
                                                      return _c(
                                                        "b-alert",
                                                        {
                                                          key: index,
                                                          staticClass: "mt-4",
                                                          attrs: {
                                                            variant: "danger",
                                                            show: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(msg) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id: "confirm-audio-modal",
                                      title: "Confirm audio",
                                      "ok-title": "Confirm",
                                      "cancel-title": "Cancel",
                                      "ok-disabled":
                                        _vm.audioReplacementReason.length < 5
                                    },
                                    on: { ok: _vm.replaceAudio }
                                  },
                                  [
                                    _c("page-snippet", {
                                      attrs: { name: "audio-replace" }
                                    }),
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "textarea",
                                        placeholder: "Enter reason...",
                                        rows: "3",
                                        "max-rows": "6"
                                      },
                                      model: {
                                        value: _vm.audioReplacementReason,
                                        callback: function($$v) {
                                          _vm.audioReplacementReason = $$v
                                        },
                                        expression: "audioReplacementReason"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.audioReplacement &&
                                _vm.audioReplacementStatus == "progress"
                                  ? [
                                      _c("b-spinner", {
                                        attrs: { label: "Spinning" }
                                      })
                                    ]
                                  : _vm._e(),
                                _vm.audioReplacement &&
                                _vm.audioReplacementStatus == "success"
                                  ? [
                                      _vm._v(
                                        " Audio replacement is in progress. It can take up to an hour before the new track can be played on the website. "
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.audioReplacement &&
                                _vm.audioReplacementStatus == "error"
                                  ? [
                                      _vm._v(
                                        " An error occurred during upload, please try again."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.audioReplacementErrorMsg) +
                                          " "
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Albums" } },
                [
                  _c("release-list", {
                    attrs: {
                      filter: { TrackMetadataID: _vm.track.TrackMetadataID }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("contributor-tags-attributes", {
        attrs: {
          holder: "Track",
          holderId: _vm.track.TrackMetadataID,
          productDetailsId: _vm.track.ProductDetails.ProductDetailsID
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }