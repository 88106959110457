<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <router-link v-if="albumId" :to="{ name: 'release-detail', params: { id: albumId } }">
          Back to album...
        </router-link>

        <b-card header="Track" class="mt-4">

          <table class="table table-bordered">
            <tr>
              <th>Reporting Code</th><td>{{ track.ProductDetails.ReportingCode }}</td>
              <th>Medley</th><td>{{ track.IsMedley|yesno }}</td>
            </tr>
            <tr>
              <th>ISRC</th><td>{{ track.ProductDetails.ISRC }}</td>
              <th>Single Sale Allowed</th><td>{{ track.SingleSaleAllowed|yesno }}</td>
            </tr>
            <tr>
              <th>Attribution</th><td>{{ track.ProductDetails.Attribution }}</td>
              <th>Recompilation Rights</th><td>{{ track.ProductDetails.RecompilationRightsID }}</td>
            </tr>
            <tr>
              <th>Title</th><td>{{ track.ProductDetails.Title }}</td>
              <th>Duration</th><td>{{ track.Duration }}</td>
            </tr>
            <tr>
              <th>Version Title</th><td>{{ track.ProductDetails.VersionTitle }}</td>
              <th>BPM</th><td>{{ track.BPM }}</td>
            </tr>
            <tr>
              <th>C-Line</th><td>{{ track.CLine}}</td>
              <th>Title Language</th><td>{{ track.ProductDetails.TitleLanguageID|localeDescription }}</td>
            </tr>
            <tr>
              <th>P-Line</th><td>{{ track.PLine }}</td>
              <th>Lyrics Language</th><td>{{ track.LyricsLanguageID|localeDescription }}</td>
            </tr>
            <tr>
              <th>Label</th><td>{{ track.Label }}</td>
              <th>Provider</th><td>{{ track.Provider.Description }}</td>
            </tr>
            <tr>
              <th>Termset Reference</th><td>{{ track.ProductDetails.TermSet.Reference }}</td>
              <th>Sub Provider</th><td>{{ track.SubProvider.Description }}</td>
            </tr>
            <tr>
              <th>Preferred Source</th><td>{{ track.ProductDetails.PrefSource|yesno }}</td>
              <th>Genre Processed</th><td>{{ track.ProductDetails.GenreProcessed|yesno }}</td>
            </tr>
            <tr>
              <th>Preferred Source Processed</th><td>{{ track.ProductDetails.PreferredSourceProcessed|yesno }}</td>
              <th></th><td></td>
            </tr>

            <tr v-if="track.WorkTitle || track.Key">
              <th v-if="track.WorkTitle">Work title</th><td>{{ track.WorkTitle }}</td>
              <th v-if="track.Key">Key</th><td>{{ track.Key }}</td>
            </tr>
            <tr v-if="track.MovementTitle || track.ClassicalCatalogNr">
              <th v-if="track.MovementTitle">Movement Title</th><td>{{ track.MovementTitle }}</td>
              <th v-if="track.ClassicalCatalogNr">Classical Catalog Nr</th><td>{{ track.ClassicalCatalogNr }}</td>
            </tr>
            <tr v-if="track.MovementTitle">
              <th></th><td></td>
              <th>Movement nr</th><td>{{ track.MovementNr }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-center">
                <a 
                  class="d-block mb-1"
                  v-if="$root.$data.me.access.ACE"
                  :href="ceUrl"
                >
                  View in Content-Editor
                </a>

                <template v-if="track.AssetFile.IsPresent == 1">
                  <b-button
                    v-if="audioFile == ''"
                    v-on:click="play()">
                    Play sample
                  </b-button>

                  <p v-if="audioPlayWarning">{{ audioPlayWarning }}</p>

                  <audio-player v-if="audioFile" :src="audioFile" />
                </template>
              </td>
              <td colspan="2" class="text-center">
                <template v-if="track.Security.ChangeAudio">
                  <p><strong>Replace audio:</strong></p>

                  <blob-upload v-on:input="audioReplacement = $event" class="mb-4" />

                  <template v-if="audioReplacement && audioReplacementStatus == null">
                    <b-progress v-if="audioReplacement.status == 'uploading'" :value="audioReplacement.uploadPercentage" :max="100" show-progress />

                    <template v-if="audioReplacement.status == 'uploadError'">
                      An error occurred during upload, please try again.
                    </template>
                    
                    <template v-if="audioReplacement.status == 'uploadComplete'">
                      <template v-if="audioReplacementValid == true">
                        <audio-player :src="audioReplacementSrc" />

                        <b-button class="mt-3" @click.prevent v-b-modal.confirm-audio-modal>Confirm audio</b-button>
                      </template>

                      <template v-if="audioReplacementValid != true">
                        Problems found with uploaded file:
                        <b-alert v-for="(msg, index) in audioReplacementValid" :key="index" variant="danger" class="mt-4" show>
                          {{ msg }}
                        </b-alert>
                      </template>
                    </template>
                  </template>

                  <b-modal id="confirm-audio-modal" title="Confirm audio" ok-title="Confirm" cancel-title="Cancel" 
                            :ok-disabled="audioReplacementReason.length < 5" @ok="replaceAudio">
                    <page-snippet name="audio-replace" />
                    <b-form-textarea
                      id="textarea"
                      v-model="audioReplacementReason"
                      placeholder="Enter reason..."
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-modal>

                  <template v-if="audioReplacement && audioReplacementStatus == 'progress'"> 
                    <b-spinner label="Spinning"></b-spinner>
                  </template>

                  <template v-if="audioReplacement && audioReplacementStatus == 'success'"> 
                    Audio replacement is in progress. It can take up to an hour before the new track can be played on the website.
                  </template>

                  <template v-if="audioReplacement && audioReplacementStatus == 'error'"> 
                    An error occurred during upload, please try again.<br />

                    {{ audioReplacementErrorMsg }}
                  </template>
                </template>
              </td>
            </tr>
          </table>
        </b-card>

        <b-card header="Albums" class="mt-4">
          <release-list :filter="{ TrackMetadataID: track.TrackMetadataID }" />
        </b-card>

      </b-col>
    </b-row>

    <contributor-tags-attributes holder="Track" :holderId="track.TrackMetadataID" :productDetailsId="track.ProductDetails.ProductDetailsID" />

  </b-container>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue'
import ContributorTagsAttributes from '@/components/ContributorTagsAttributes.vue'
import { sleep } from '@/functions'

export default {
  name: "TrackDetail",
  components: {
    AudioPlayer,
    ContributorTagsAttributes,
    'blob-upload': () => import('@/components/BlobUpload.vue'),
    'release-list': () => import('@/components/ReleaseList.vue'),
    'page-snippet': () => import('@/components/PageSnippet'),
  },
  data() {
    return {
      audioPlayWarning: '',
      audioFile: '',
      audioReplacement: null,
      audioReplacementStatus: null,
      audioReplacementReason: '',
      audioReplacementSrc: null,
      audioReplacementErrorMsg: '',
    };
  },
  props: {
    track: Object,
    albumId: String,
  },
  computed: {
    audioReplacementValid () {
      if (this.audioReplacement && this.audioReplacement.status == 'uploadComplete') {
        return this.$validators.audioFile(this.audioReplacement)
      }
      return false
    },
      ceUrl () {
        return `${process.env.VUE_APP_CE_URL}Track/${this.track.AssetFileID}/`
      },
  },
  watch: {
    audioReplacement(file) {
      this.audioReplacementStatus = null
      
      let reader = new FileReader();
      let self = this
      reader.onload = function(e) {
        self.audioReplacementSrc = e.target.result;
      }
      reader.readAsDataURL(file.rawFile);
    }
  },
  methods: {
    play () {
      let blockTracks = localStorage.getItem('blockPlayTrack')
      if (blockTracks != null) {
        blockTracks = JSON.parse(blockTracks)
        
        let diff = new Date() - new Date(blockTracks[this.track.TrackMetadataID])
        diff = diff/1000/60
        
        if (diff < 50) {
          console.log(diff)
          this.audioPlayWarning = 'This track has been recently replaced and is being processed. This can take up to an hour. Until then, playing is disabled.'
          return 
        }
      }

      this.audioFile = 'https://assets.the-source.eu/clip/' + this.track.ProductDetails.ReportingCode + '?refresh=' + new Date().getTime()
    },
    async replaceAudio() {
      console.log("Replacing audio")
      this.audioReplacementErrorMsg = ''
      this.audioReplacementStatus = 'progress'
      if (this.audioFile != '') {
        this.audioFile = ''
      }
      let blockTracks = localStorage.getItem('blockPlayTrack')
      if (blockTracks == null) {
        blockTracks = {}
      } else {
        blockTracks = JSON.parse(blockTracks)
      }
      blockTracks[this.track.TrackMetadataID] = new Date()
      localStorage.setItem('blockPlayTrack', JSON.stringify(blockTracks))

      try {
        const response = await this.$http.put(
          'tracks/' + this.track.TrackMetadataID + '/replace-audio', 
          { 
            TemporaryBlobID: this.audioReplacement.sha,
            Reason: this.audioReplacementReason,
          }
        )
        const requestId = response.data.requestId
        
        let stop = false
        while (!stop) {
          await sleep(1)
          const response = await this.$http.get(
            'tracks/' + this.track.TrackMetadataID + '/replace-audio-status/' + requestId
          )

          const data = response.data
          if (!data) continue

          if (data.Type == 'Success') {
            this.audioReplacementStatus = 'success'
          } else {
            this.audioReplacementStatus = 'error'
            this.audioReplacementErrorMsg = data.Message
          }

          stop = true
        }

      } catch (error) {
        this.audioReplacementStatus = 'error'
      }
    },
  },
  mounted() {


    this.$root.$data.crumbs = [
      { to: { name: "track-search" }, text: "Tracks" },
      { text: this.track.ProductDetails.Title + ' - ' + this.track.ProductDetails.Attribution }
    ];
  }
};
</script>

<style lang="scss" scoped>
</style>